import React from 'react';
import PropTypes from 'prop-types';

function Instagram({ fill }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 367 367"
      version="1.1"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      fill={fill}
    >
      <g transform="matrix(1,0,0,1,-2514.22,-40935.1)">
        <g transform="matrix(8.33333,0,0,8.33333,0,22629.8)">
          <g transform="matrix(-0.999993,-0.00363348,-0.00363348,0.999993,323.634,2223.25)">
            <path
              d="M-0.068,-10.164C2.749,-10.175 5.068,-7.85 5.037,-5.056C5.027,-2.236 2.75,0.027 -0.068,0.019C-2.884,0.011 -5.152,-2.271 -5.143,-5.087C-5.134,-7.892 -2.856,-10.154 -0.068,-10.164"
              fillRule="nonzero"
            />
          </g>
        </g>
        <g transform="matrix(8.33333,0,0,8.33333,0,22629.8)">
          <g transform="matrix(1,0,0,1,331.87,2224.45)">
            <path
              d="M0,-12.597C-1.016,-12.595 -1.836,-13.416 -1.839,-14.439C-1.842,-15.455 -1.015,-16.284 0,-16.284C1.021,-16.283 1.843,-15.466 1.847,-14.447C1.851,-13.421 1.031,-12.599 0,-12.597M-8.184,1.593C-12.551,1.592 -16.091,-2.005 -16.031,-6.382C-15.973,-10.655 -12.466,-14.155 -8.154,-14.133C-3.807,-14.132 -0.3,-10.615 -0.301,-6.256C-0.303,-1.913 -3.825,1.593 -8.184,1.593M4.273,-12.656C4.233,-13.404 4.134,-14.143 3.909,-14.86C3.367,-16.588 2.24,-17.745 0.517,-18.315C-0.342,-18.599 -1.232,-18.698 -2.13,-18.726C-3.324,-18.763 -4.519,-18.801 -5.714,-18.801C-8.548,-18.799 -11.383,-18.851 -14.216,-18.728C-14.968,-18.695 -15.715,-18.621 -16.447,-18.435C-17.808,-18.088 -18.901,-17.359 -19.657,-16.164C-20.15,-15.386 -20.416,-14.517 -20.505,-13.607C-20.597,-12.657 -20.653,-11.701 -20.671,-10.747C-20.698,-9.333 -20.678,-7.918 -20.678,-6.504C-20.696,-6.503 -20.714,-6.503 -20.732,-6.503C-20.696,-4.412 -20.665,-2.32 -20.622,-0.229C-20.608,0.438 -20.533,1.101 -20.391,1.755C-20.097,3.11 -19.444,4.242 -18.303,5.058C-17.398,5.704 -16.361,6 -15.273,6.097C-14.322,6.182 -13.365,6.237 -12.41,6.241C-9.412,6.254 -6.413,6.245 -3.415,6.227C-2.484,6.222 -1.552,6.193 -0.63,6.045C0.208,5.911 1.014,5.674 1.735,5.216C2.927,4.458 3.654,3.364 4.002,2.004C4.254,1.016 4.295,0.006 4.322,-1.004C4.351,-2.124 4.371,-3.245 4.368,-4.365C4.362,-7.129 4.42,-9.893 4.273,-12.656"
              fillRule="nonzero"
            />
          </g>
        </g>
        <g transform="matrix(8.33333,0,0,8.33333,0,22629.8)">
          <g transform="matrix(1,0,0,1,339.011,2217.53)">
            <path
              d="M0,2.241C0.004,3.989 -0.003,5.737 -0.12,7.482C-0.188,8.51 -0.375,9.514 -0.724,10.487C-1.276,12.024 -2.221,13.266 -3.539,14.221C-4.818,15.148 -6.277,15.609 -7.829,15.768C-8.774,15.864 -9.727,15.879 -10.676,15.931C-10.765,15.936 -10.853,15.947 -10.941,15.955L-19.639,15.955C-19.668,15.949 -19.696,15.938 -19.725,15.937C-20.322,15.915 -20.919,15.893 -21.516,15.871C-22.478,15.837 -23.429,15.725 -24.363,15.477C-26.242,14.979 -27.77,13.965 -28.905,12.381C-29.722,11.24 -30.153,9.946 -30.371,8.572C-30.53,7.566 -30.541,6.55 -30.582,5.535C-30.588,5.382 -30.6,5.229 -30.61,5.076L-30.61,-3.772C-30.603,-3.815 -30.593,-3.859 -30.591,-3.903C-30.57,-4.455 -30.549,-5.008 -30.528,-5.56C-30.479,-6.856 -30.292,-8.124 -29.828,-9.344C-29.275,-10.796 -28.362,-11.972 -27.117,-12.888C-25.758,-13.887 -24.197,-14.356 -22.539,-14.491C-21.344,-14.589 -20.142,-14.641 -18.944,-14.641C-15.667,-14.641 -12.389,-14.719 -9.112,-14.571C-8.135,-14.527 -7.168,-14.423 -6.22,-14.168C-4.541,-13.715 -3.128,-12.844 -2.022,-11.492C-0.913,-10.137 -0.363,-8.55 -0.192,-6.836C-0.076,-5.674 -0.033,-4.502 -0.014,-3.333C0.016,-1.475 -0.004,0.383 0,2.241M6.697,-14.638C6.697,-17.807 4.531,-20.328 1.388,-20.825C1.346,-20.831 1.307,-20.862 1.267,-20.881L-31.873,-20.881C-32.45,-20.715 -33.06,-20.617 -33.6,-20.369C-35.656,-19.424 -36.891,-17.825 -37.248,-15.573C-37.255,-15.53 -37.285,-15.491 -37.305,-15.45L-37.305,17.69C-37.169,18.182 -37.07,18.687 -36.892,19.163C-36.012,21.523 -33.711,23.119 -31.199,23.119C-20.613,23.122 -10.027,23.122 0.56,23.119C3.636,23.118 6.239,20.821 6.636,17.768C6.674,17.474 6.695,17.176 6.695,16.88C6.698,6.374 6.698,-4.132 6.697,-14.638"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
Instagram.defaultProps = {
  fill: 'rgb(35, 31, 32)',
};
Instagram.propTypes = {
  fill: PropTypes.string,
};
export default Instagram;
