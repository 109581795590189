import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import MenuBar from '../partials/global/MenuBar';
import AboutFooter from '../partials/pages/AboutFooter';
import PageContent from '../partials/sections/PageContent';

function AboutUs(props) {
  const {
    data: { aboutUsPage },
  } = props;
  const {
    template: {
      pageContent: {
        pageSection,
      },
    },
  } = aboutUsPage;

  return (
    // Bug - Layout breaks when section is replaced with fragment
    <section className="full-height-section">
      <Seo post={aboutUsPage} />
      <MenuBar />
      <PageContent pageSections={pageSection} />
      <AboutFooter />
    </section>
  );
}

export const query = graphql`
  query($pageId: String){
    aboutUsPage: wpPage( id: {eq: $pageId}) {
      seo {
        ...YoastPageSEOContent
      }
      id
      title
      template {
        ... on WpTemplate_AboutUs {
          templateName
          pageContent {
            pageSection {
              ... on WpTemplate_AboutUs_Pagecontent_PageSection_BannerSection {
                bannerContent
                fieldGroupName
                bannerSlides {
                  fieldGroupName
                  slideContent
                  slideImage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                    altText
                  }
                }
              }
              ... on WpTemplate_AboutUs_Pagecontent_PageSection_Section {
                fieldGroupName
                backgroundColor
                sectionColumn {
                  ... on WpTemplate_AboutUs_Pagecontent_PageSection_Section_SectionColumn_ImageBlock {
                    caption
                    columnWidth
                    fieldGroupName
                    imageUrl
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(layout: CONSTRAINED)
                        }
                      }
                    }
                  }
                  ... on WpTemplate_AboutUs_Pagecontent_PageSection_Section_SectionColumn_TextBlock {
                    columnWidth
                    description
                    fieldGroupName
                  }
                  ... on WpTemplate_AboutUs_Pagecontent_PageSection_Section_SectionColumn_EmbedBlock {
                    caption
                    columnWidth
                    embedCode
                    fieldGroupName
                  }
                }
              }
            }
            fieldGroupName
          }
        }
      }
    }
  }
`;

AboutUs.propTypes = {
  data: PropTypes.shape({
    aboutUsPage: PropTypes.shape({
      template: PropTypes.shape({
        pageContent: PropTypes.shape({
          pageSection: PropTypes.arrayOf(
            PropTypes.shape({}),
          ),
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};
export default AboutUs;
