import React from 'react';
import PropTypes from 'prop-types';

function Facebook({ fill }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 367 367"
      version="1.1"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      fill={fill}
    >
      <g transform="matrix(1,0,0,1,-4407.58,-40935)">
        <g transform="matrix(8.33333,0,0,8.33333,0,22629.8)">
          <g transform="matrix(1,0,0,1,545.941,2201.73)">
            <path
              d="M0,33.835L7.572,33.835L7.572,16.784L12.791,16.784L12.791,10.535L7.528,10.535C7.528,10.125 7.527,9.734 7.528,9.342C7.528,8.789 7.522,8.236 7.532,7.683C7.546,6.88 8.037,6.338 8.788,6.337C9.801,6.336 10.814,6.369 11.827,6.385C12.137,6.389 12.448,6.385 12.757,6.385C12.99,4.365 13.222,2.352 13.456,0.324C13.068,0.283 12.698,0.23 12.326,0.206C11.144,0.129 9.963,0.016 8.781,0.003C7.394,-0.013 6.006,0.038 4.621,0.107C3.705,0.153 2.852,0.45 2.112,1.007C0.751,2.031 0.159,3.47 0.094,5.107C0.024,6.887 0.076,8.671 0.074,10.454L0.074,10.687L-3.44,10.687C-3.415,12.731 -3.389,14.754 -3.364,16.793L0,16.793L0,33.835ZM27.003,16.922L27.003,34.918C27.003,36.935 25.51,38.635 23.503,38.9C23.346,38.921 23.185,38.934 23.027,38.934C10.998,38.935 -1.03,38.935 -13.058,38.935C-14.944,38.935 -16.64,37.505 -16.958,35.638C-17.003,35.374 -17.029,35.102 -17.029,34.834C-17.032,22.88 -17.032,10.925 -17.031,-1.029C-17.031,-3.045 -15.596,-4.734 -13.608,-5.053C-13.421,-5.083 -13.229,-5.097 -13.039,-5.097C-1.017,-5.099 11.005,-5.1 23.026,-5.098C24.993,-5.098 26.729,-3.554 26.966,-1.606C26.988,-1.423 27.001,-1.239 27.001,-1.055C27.003,4.937 27.003,10.93 27.003,16.922"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
Facebook.defaultProps = {
  fill: 'rgb(35, 31, 32)',
};
Facebook.propTypes = {
  fill: PropTypes.string,
};
export default Facebook;
