import React from 'react';
import PropTypes from 'prop-types';

function Twitter({ fill }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 368 368"
      version="1.1"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      fill={fill}
    >
      <g transform="matrix(1,0,0,1,-5338.17,-40934.6)">
        <g transform="matrix(8.33333,0,0,8.33333,0,22629.8)">
          <g transform="matrix(1,0,0,1,649.606,2209.5)">
            <path
              d="M0,18.312C0.525,18.591 1.045,18.879 1.575,19.146C3.128,19.927 4.751,20.499 6.482,20.724C8.556,20.994 10.614,20.854 12.644,20.388C17.587,19.254 21.256,16.447 23.565,11.918C24.916,9.27 25.498,6.422 25.605,3.468C25.612,3.265 25.667,3.129 25.83,3.005C26.129,2.777 26.417,2.531 26.697,2.28C27.185,1.842 27.636,1.371 27.9,0.756C27.95,0.639 27.975,0.512 28.029,0.334C27.045,0.778 26.073,0.988 25.078,1.136C26.115,0.27 26.985,-0.694 27.357,-2.093C27.122,-1.992 26.945,-1.919 26.771,-1.841C25.839,-1.428 24.912,-1.002 23.896,-0.823C23.711,-0.79 23.596,-0.82 23.47,-0.966C22.794,-1.748 21.945,-2.263 20.938,-2.486C18.819,-2.957 16.92,-2.44 15.267,-1.077C13.903,0.048 13.224,1.52 13.315,3.31C13.335,3.701 13.4,4.089 13.446,4.497C8.548,4.174 4.612,1.955 1.381,-1.647C0.107,0.134 0.557,4.573 3.032,6.271C2.182,6.264 1.432,6.052 0.701,5.648C0.859,8.676 2.356,10.619 5.219,11.52C5.205,11.551 5.192,11.581 5.179,11.612L2.942,11.612C3.06,12.183 3.679,13.257 4.239,13.868C5.338,15.069 6.749,15.608 8.343,15.8C5.886,17.714 3.091,18.443 0,18.312M35.106,9.144C35.106,15.064 35.111,20.984 35.103,26.904C35.101,28.985 33.429,30.903 31.362,31.141C30.453,31.245 29.527,31.207 28.609,31.208C19.333,31.211 10.058,31.21 0.782,31.21C-1.029,31.21 -2.84,31.221 -4.651,31.205C-6.765,31.186 -8.546,29.691 -8.944,27.615C-8.999,27.328 -9.022,27.031 -9.022,26.739C-9.026,20.506 -9.025,14.273 -9.025,8.04C-9.025,2.543 -9.021,-2.955 -9.026,-8.452C-9.028,-10.94 -7.049,-12.924 -4.569,-12.923C3.907,-12.919 12.383,-12.923 20.859,-12.923C24.15,-12.923 27.441,-12.933 30.732,-12.917C32.847,-12.907 34.63,-11.406 35.023,-9.329C35.089,-8.979 35.104,-8.616 35.104,-8.26C35.109,-2.459 35.107,3.343 35.107,9.144L35.106,9.144Z"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
Twitter.defaultProps = {
  fill: 'rgb(35, 31, 32)',
};
Twitter.propTypes = {
  fill: PropTypes.string,
};
export default Twitter;
