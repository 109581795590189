import React from 'react';

import KaguraCurve from '../../components/presentation/KaguraCurve';
import Facebook from '../../components/svgs/icons/Facebook';
import TikTok from '../../components/svgs/icons/TikTok';
import Twitter from '../../components/svgs/icons/Twitter';
import Instagram from '../../components/svgs/icons/Instagram';

import * as styles from './AboutFooter.module.scss';

function AboutFooter() {
  return (
    <div className={[styles.aboutUsFooter, 'overflow-hidden'].join(' ')}>
      <KaguraCurve className="mb-4" hasAnimation />
      <div className="container-fluid" style={{ position: 'relative', zIndex: 1 }}>
        <div className="row">
          <ul className={styles.socialMediaList}>
            <li>
              <a href="https://www.instagram.com/ramenkagura/" target="_blank" rel="noreferrer">
                <Instagram />
                <span>@RAMENKAGURA</span>
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@ramenkagura" target="_blank" rel="noreferrer">
                <TikTok />
                <span>@RAMENKAGURA</span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/ramenkagura" target="_blank" rel="noreferrer">
                <Facebook />
                <span>@RAMENKAGURA</span>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/ramen_kagura" target="_blank" rel="noreferrer">
                <Twitter />
                <span>@RAMEN_KAGURA</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default AboutFooter;
