import React from 'react';
import PropTypes from 'prop-types';

function TikTok({ fill }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 367 367"
      version="1.1"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      fill={fill}
    >
      <g transform="matrix(1,0,0,1,-3488.62,-40935.1)">
        <g transform="matrix(8.33333,0,0,8.33333,0,22629.8)">
          <g transform="matrix(1,0,0,1,440.637,2233.55)">
            <path
              d="M0,-29.809L0,-29.33C0,-23.074 0.001,-16.817 -0.002,-10.561C-0.002,-10.276 -0.021,-9.989 -0.055,-9.706C-0.333,-7.367 -2.649,-5.718 -4.952,-6.191C-6.713,-6.552 -8.37,-8.262 -8.258,-10.483C-8.155,-12.534 -6.444,-14.299 -4.398,-14.345C-3.903,-14.356 -3.406,-14.29 -2.907,-14.259L-2.907,-19.241C-3.296,-19.261 -3.661,-19.308 -4.023,-19.292C-4.65,-19.262 -5.287,-19.252 -5.899,-19.131C-10.738,-18.182 -13.89,-13.573 -13.061,-8.691C-12.174,-3.462 -6.911,-0.079 -1.806,-1.457C2.208,-2.54 4.96,-6.13 4.959,-10.284C4.959,-13.446 4.959,-16.609 4.96,-19.771C4.96,-19.89 4.969,-20.01 4.976,-20.17C6.012,-19.463 7.077,-18.922 8.231,-18.57C9.38,-18.219 10.548,-17.996 11.749,-18.063L11.749,-22.893C7.56,-23.351 5.338,-25.694 4.838,-29.809L0,-29.809ZM-22.002,1.665L-22.002,-31.475C-21.983,-31.515 -21.953,-31.555 -21.946,-31.597C-21.588,-33.85 -20.353,-35.449 -18.297,-36.394C-17.757,-36.642 -17.148,-36.739 -16.57,-36.906L16.569,-36.906C16.61,-36.887 16.648,-36.856 16.691,-36.849C19.833,-36.353 21.999,-33.832 22,-30.663C22,-20.157 22,-9.651 21.997,0.855C21.997,1.151 21.976,1.449 21.938,1.743C21.542,4.796 18.939,7.093 15.862,7.094C5.276,7.097 -5.31,7.097 -15.896,7.094C-18.409,7.094 -20.709,5.498 -21.589,3.138C-21.767,2.662 -21.866,2.157 -22.002,1.665"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
TikTok.defaultProps = {
  fill: 'rgb(35, 31, 32)',
};
TikTok.propTypes = {
  fill: PropTypes.string,
};
export default TikTok;
